import {Component} from '@angular/core';
import {Title} from '@angular/platform-browser';
import { DeviceDetectorService } from 'ngx-device-detector';
import {environment} from '../../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {
    collapsedSideBar: boolean;
    isMobile = false;
    isTablet = false;
    isDesktopDevice = false;

    constructor(
        private titleService: Title,
        private deviceService: DeviceDetectorService
    ) {

        this.isMobile = this.deviceService.isMobile();
        this.isTablet = this.deviceService.isTablet();
        this.isDesktopDevice = this.deviceService.isDesktop();

        if (!environment.production) {
            this.titleService.setTitle('SwitchPay Portal - Dev');
        }
    }

    receiveCollapsed($event) {
        this.collapsedSideBar = $event;
    }
}
